import React, { createRef } from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import LoggedOutHome from './logged_out_home';
import Dashboard from './dashboard';
import ConnectModal from './connect_modal';
import queryString from 'query-string';
import axios from 'axios';
import SlidingPane from 'react-sliding-pane';
import Message from './message';
import WarningBanner from './warning_banner';
import mountFb, { defaultScopes as defaultFacebookScopes, getFbLoginForBusinessPageUri } from './mount_fb';
import { metaTag, shimBugsnag } from './utilities'

if (metaTag("rails_env") === 'production') {
  Bugsnag.start({
    apiKey: '18cad3113419807c5761606144cf0dc8',
    plugins: [new BugsnagPluginReact()],
  });
} else {
  shimBugsnag(Bugsnag);
}

let lastStatusResponseJSON = null;
let isConnecting = false;
let hashChangeHandler;

const extractHash = () => {
  let hashSource = (window.location.hash || '').replace(/^#/, '');

  return queryString.parse(hashSource);
};

const adjustedHash = (sourceData, replace = false) => {
  if (replace) {
    return queryString.stringify(sourceData);
  }

  return queryString.stringify({
    ...extractHash(),
    ...sourceData,
  });
}

export default class AppContainer extends React.Component {
  constructor(props) {
    super(props);

    let page = document.querySelector('#js-app').dataset.page ?? 'root';
    let igConnectAction = document.querySelector('#js-app').dataset.igConnect == 'true';
    let showModalInvite = false;
    let startingStage = 2;
    let showHome = true;
    let description = '';
    let location = queryString.parse(window.location.search);
    let hash = extractHash();
    let providedUsername;
    let brand = null;
    let brandName = null;
    let brandImageUrl = null;
    let heroImagePath = '/images/sample/analyze.svg';
    let title = null;
    let data = null;
    let username = null;
    let usernameInput = null;
    let conversations = { index: [], data: {} };
    let initialFbPageCheckbox = false;
    let requestMessagePermissions = true;
    let useFbLoginForBusinessPage = true;
    let initialState = {};
    let sessionId = localStorage.getItem('mcSession') ?? null;
    let msSessionToken = hash.st ?? localStorage.getItem('msSessionToken') ?? null;
    const hasAccessDeniedError = location['error'] === 'access_denied'; // user backed out of facebook flow

    Bugsnag.leaveBreadcrumb('App loaded', { hash: hash });

    // Clear data when landing at root or brand page (and not connecting)
    if ((page === 'root' || page === 'brand') && !igConnectAction) {
      this.clearIgConnectData();
    }

    let sourceMsManage = (hash.source == 'ms' || hash.source == 'ms:manage' || hash.source == 'ms:manage:no-messages') || localStorage.getItem('igConnect.sourceMsManage')?.toString() == 'true';
    let sourceMsManageMessages = localStorage.getItem('igConnect.sourceMsManageMessages')?.toString() == 'true';

    if (sessionId?.length > 0) {
      Bugsnag.leaveBreadcrumb('Existing session present', { sessionId: sessionId });
    }

    if (msSessionToken?.length > 0) {
      Bugsnag.leaveBreadcrumb('Existing MS session token present', { msSessionToken: msSessionToken });
    }

    if (page === 'root' && sessionId?.length > 0 && !igConnectAction) {
      const connectingNewState = this.showConnectingProfile(true);

      initialState = {
        ...connectingNewState,
        showHome: false,
        userAvatarUrl: '/images/sample/analyze.svg',
        heroImagePath: '/images/sample/analyze.svg',
        fbStatus: 'connected',
        username: null,
        alertRestartError: hasAccessDeniedError,
        errorCode: hasAccessDeniedError ? "access_denied" : null,
      };

      Bugsnag.leaveBreadcrumb('Retrieving session information');
      axios({
        method: 'get',
        url: `/session`,
        headers: {
          'Accept': 'application/json',
          'X-Session-ID': sessionId,
        }
      })
        .then(res => {
          Bugsnag.leaveBreadcrumb('Retrieved session information', { response: res.data }, 'request');
          if (res.data.session_id && res.data.user?.username) {
            this.setState({
              data: res.data.data,
              showModalInvite: false,
              showDashboard: true,
              username: res.data.user.username,
              sourceMsManageMessages: res.data.data.profile.managed_messages ?? false,
            });
          } else {
            localStorage.removeItem('mcSession');

            this.clearIgConnectData();

            this.setState({
              alertRestartError: true,
              title: 'Session Expired',
              description: 'Your session has expired. Please try again.',
              heroImagePath: '/images/sample/green-ig.png'
            });
          }
        })
        .catch(err => {
          Bugsnag.leaveBreadcrumb('Session retrieval error', { response: err.response }, 'request');

          localStorage.removeItem('mcSession');

          this.clearIgConnectData();

          this.setState({
            alertRestartError: true,
            title: "There's an issue",
            description: 'There was an error loading your account. Please try again.',
            heroImagePath: '/images/sample/red-ig.png'
          });
        });
    }

    providedUsername = document.querySelector('#js-app').dataset.providedUsername || hash.username || null;
    brand = document.querySelector('#js-app').dataset.brandSlug || localStorage.getItem('igConnect.brand') || null;
    brandName = document.querySelector('#js-app').dataset.brandName || localStorage.getItem('igConnect.brandName') || null;
    brandImageUrl = document.querySelector('#js-app').dataset.brandImageUrl || localStorage.getItem('igConnect.brandImageUrl') || null;

    if (page === 'brand') {
      showModalInvite = true;
      startingStage = 1;
      showHome = false;
      requestMessagePermissions = sourceMsManage; // only request message permissions for a brand if it's a managed account

      if (providedUsername) {
        localStorage.setItem('igConnect.providedUsername', providedUsername);
      }

      if (brand) {
        localStorage.setItem('igConnect.brand', brand);
        localStorage.setItem('igConnect.brandName', brandName);
        localStorage.setItem('igConnect.brandImageUrl', brandImageUrl);
      }

      localStorage.setItem('igConnect.requestMessagePermissions', sourceMsManage);
      localStorage.setItem('igConnect.sourceMsManage', sourceMsManage);
      localStorage.setItem('igConnect.sourceMsManageMessages', sourceMsManageMessages);

      if (msSessionToken) {
        localStorage.setItem('igConnect.msSessionToken', msSessionToken);
      }

      Bugsnag.leaveBreadcrumb('Loading brand page', { providedUsername: providedUsername, brand: brand, brandName: brandName, brandImageUrl: brandImageUrl, requestMessagePermissions: requestMessagePermissions, sourceMsManage: sourceMsManage, sourceMsManageMessages: sourceMsManageMessages });
    }

    if (igConnectAction) {
      Bugsnag.leaveBreadcrumb('On page for IG Connect action');

      // Strip out the hash from the URL
      window.location.hash = adjustedHash({ connecting: 'true' });

      providedUsername = providedUsername || localStorage.getItem('igConnect.providedUsername');
      brand = brand || localStorage.getItem('igConnect.brand');
      brandName = brandName || localStorage.getItem('igConnect.brandName');
      brandImageUrl = brandImageUrl || localStorage.getItem('igConnect.brandImageUrl');
      requestMessagePermissions = requestMessagePermissions || localStorage.getItem('igConnect.requestMessagePermissions')?.toString() == 'true';
      sourceMsManage = sourceMsManage || localStorage.getItem('igConnect.sourceMsManage')?.toString() == 'true';
      sourceMsManageMessages = sourceMsManageMessages || localStorage.getItem('igConnect.sourceMsManageMessages')?.toString() == 'true';
      msSessionToken = msSessionToken || localStorage.getItem('igConnect.msSessionToken');

      useFbLoginForBusinessPage = true;
      page = brand ? 'brand' : page;

      // The docs say that we get all four of these params back from FB, but we may not -- the most important one is the `access_token`
      // so we'll consider it a success if that is present. If we want/need the others, we can remove this line and uncomment the one
      // below it to do so, but I don't expect we will need it.
      const igConnectSuccess = (hash.access_token?.length > 0 || hash.long_lived_token?.length > 0);
      // const igConnectSuccess = (hash.access_token?.length > 0 && hash.data_access_expiration_time && hash.expires_in && hash.long_lived_token);

      showModalInvite = true;
      startingStage = 1;
      showHome = false;

      if (igConnectSuccess) {
        Bugsnag.leaveBreadcrumb('Connection data present on page load');

        setTimeout(() => {
          const connectionData = {
            brand: brand,
            fbUserId: null,
            primaryInstagramId: null,
            primaryInstagramUsername: null,
            accessToken: hash.access_token,
            longAccessToken: hash.long_lived_token,
            providedUsername: providedUsername,
            msSessionToken: msSessionToken,
          };

          this.handleConnectionFrom(connectionData);
        }, 250);
      } else {
        Bugsnag.leaveBreadcrumb('Connection data not present on page load');

        // FB redirects back here with query params including the following params:
        //   error_reason=user_denied
        //   error=access_denied
        //   error_description=Permissions+error.
        //
        // These appear to be static, so we may not need to bother with the particular values.
        const igFailureDetails = (new URL(document.location)).searchParams;

        if (igFailureDetails?.get('error') == 'access_denied') {
          Bugsnag.leaveBreadcrumb('Explicit error from FB in query params', {}, 'error');

          // There was definitely a failure / cancellation, show them the error as such
          initialState = {
            alertRestartError: false,
            title: 'Connection cancelled',
            description: "It looks like you've cancelled the connection process. Please try again.",
            heroImagePath: '/images/sample/red-ig.png'
          };

          window.location.hash = adjustedHash({ connection_cancelled: 'true' });
        } else {
          Bugsnag.leaveBreadcrumb('No connection data present on page load');

          // There was no access token present, so we'll assume that the user is starting the connection process
          // and we'll wait to see if the hash changes and retrigger it based on that change (this shouldn't ever
          // happen in practice due to how FB redirects, but it's a safety net)
          const connectingNewState = this.showConnectingProfile(true);

          initialState = {
            ...connectingNewState,
            showHome: false,
            userAvatarUrl: '/images/sample/analyze.svg',
            heroImagePath: '/images/sample/analyze.svg',
            username: null,
            alertRestartError: false,
          };
        }
      }
    }

    if (brand) {
      let invitePhrase = brandName + ' invites you to join their brand ambassador program';

      if (brand == 'trendhim') {
        invitePhrase = "Help us Help YOU 😊 If you give us permission to access your posts and stories' stats, we can analyse and help you improve. It's a win–win!";
      } else if (sourceMsManage && (!hash.ambassador || hash.ambassador == 'false')) {
        if (sourceMsManageMessages) {
          invitePhrase = brandName + ' invites you to connect your Instagram account to help manage DMs and mentions.';
        } else {
          invitePhrase = brandName + ' invites you to connect your Instagram account to help manage mentions.';
        }
      }

      description = invitePhrase;
      heroImagePath = brandImageUrl;
    }

    if (hash.username) {
      username = hash.username;
      usernameInput = username;
    } else if (location.invite) {
      username = location.invite;
      usernameInput = username;
    }

    if (hash.confirmed == 'true') {
      initialFbPageCheckbox = true;
    }

    if (hash.useLegacyLogin?.length) {
      useFbLoginForBusinessPage = false;
    }

    initialState.alertRestartError = hasAccessDeniedError;
    initialState.errorCode = hasAccessDeniedError ? "access_denied" : null;

    this.state = {
      page: page,
      data: data,
      showHome: showHome,
      showModalInvite: showModalInvite,
      showDashboard: false,
      fbStatus: 'not_connected',
      signupType: 'default',
      stage: startingStage,
      loggedIn: false,
      fbPageCheckbox: initialFbPageCheckbox,
      brand: brand,
      brandName: brandName,
      brandImageUrl: brandImageUrl,
      username: username,
      usernameInput: usernameInput,
      mediaFilter: 'posts',
      title: title,
      primaryInstagramId: null,
      isBusinessAccount: false,
      businessAccountChecked: false,
      userAvatarUrl: '/images/sample/analyze.svg', // TODO replace with default avatar
      description: description,
      heroImagePath: heroImagePath,
      conversations: conversations,
      conversationId: null,
      conversationContainerRef: createRef(),
      conversationMessageText: '',
      conversationMessageRef: createRef(),
      isPaneOpen: false,
      isSendingMessage: false,
      isLoadingConversation: false,
      isLoadingConversations: false,
      conversationLoadCount: {},
      conversationsLoadCount: 0,
      panelData: null,
      skipReload: false,
      sourceMsManage: sourceMsManage,
      sourceMsManageMessages: sourceMsManageMessages,
      requestMessagePermissions: requestMessagePermissions,
      useFbLoginForBusinessPage: useFbLoginForBusinessPage,
      igConnectAction: igConnectAction,
      sessionId: sessionId,
      msSessionToken: msSessionToken,
      successCode: null,
      isPersistingPreferences: false,
      ...initialState,
    };
  }

  componentDidMount() {
    if (metaTag("rails_env") === 'development' || metaTag("rails_env") === 'test') {
      const response = { status: 'connected' };
      this.initialLoginStatus(response);
    } else {
      this.loadFb();
    }

    hashChangeHandler = this.handleHashChange.bind(this);
    window.addEventListener('hashchange', hashChangeHandler, false);
  }

  componentWillUnmount() {
    window.removeEventListener('hashchange', hashChangeHandler, false);
  }

  handleHashChange() {
    const hash = extractHash();

    if (hash.connection_cancelled == 'true') {
      return;
    }

    if (hash.access_token?.length > 0 || hash.long_lived_token?.length > 0) {
      const providedUsername = document.querySelector('#js-app').dataset.providedUsername ?? hash.username ?? localStorage.getItem('igConnect.providedUsername') ?? null;

      const connectionData = {
        fbUserId: null,
        primaryInstagramId: null,
        primaryInstagramUsername: null,
        accessToken: hash.access_token,
        longAccessToken: hash.long_lived_token,
        providedUsername: providedUsername,
        msSessionToken: this.state.msSessionToken ?? hash.st ?? localStorage.getItem('igConnect.msSessionToken') ?? null,
      };

      this.handleConnectionFrom(connectionData);
    }
  }

  loadFb() {
    mountFb({
      scopes: this.buildLoginScopes.bind(this),
      onInitialStatus: this.initialLoginStatus.bind(this), // Immediately check if user is logged in and has connected with our app before
      onStatusChange: this.statusChangeCallback.bind(this), // Show "logging in" status and message that they may need to allow popups
      onLoginResponse: this.loggingIntoFacebook.bind(this),
      onLogoutResponse: this.loggedOutOfFacebook.bind(this),
    });
  }

  showLoggingOut() {
    this.setState({ description: "Logging you out..." });
  }

  buildLoginScopes() {
    const loginScopes = [
      ...defaultFacebookScopes
    ];

    // Ambassador / unmanaged brand; do not ask for instagram_manage_messages
    if (!this.state.requestMessagePermissions) {
      const messagesScopeIndex = loginScopes.indexOf('instagram_manage_messages');

      if (messagesScopeIndex >= 0) {
        loginScopes.splice(messagesScopeIndex, 1);
      }
    }

    return loginScopes;
  }

  initialLoginStatus(response) {
    this.setState({ fbStatus: response.status, fbResponse: response });

    if (this.state.page === 'root') {
      console.log('Loading FB immediately...', response);
        this.statusChangeCallback(response);
      }
    }

  checkLoginStatus() {
    window.checkLoginState();
  }

  loginWithFacebook() {
    if (metaTag("rails_env") === 'development' || metaTag("rails_env") === 'test') {
      const response = { status: 'connected', authResponse: 'true' };
      this.loggingIntoFacebook(response);
    } else {
      window.fbLogin();
    }
  }

  loggingIntoFacebook(response) {
    if (response.authResponse) {
      this.showConnectingProfile();
      this.statusChangeCallback(response);
    } else {
      // user cancelled/closed fb login modal do nothing
    }
  }

  loggedOutOfFacebook(_response) {
    if (this.state.skipReload) {
      return false;
    }

    window.location.reload();

    return false;
  }

  statusChangeCallback(response) {
    const responseJSON = JSON.stringify(response);
    const sameAsLastResponse = (responseJSON == lastStatusResponseJSON);

    lastStatusResponseJSON = responseJSON;

    if (response.status === "connected" && sameAsLastResponse) {
      const connectionData = {
        fbUserId: response.authResponse.userID,
        primaryInstagramId: this.state.primaryInstagramId,
        primaryInstagramUsername: this.state.username,
        accessToken: response.authResponse.accessToken,
        longAccessToken: null,
        msSessionToken: this.state.msSessionToken ?? localStorage.getItem('igConnect.msSessionToken') ?? null,
      };

      this.handleConnectionFrom(connectionData);
    }
  }

  handleConnectionFrom(connectionData) {
    if (isConnecting) {
      return;
    }

    Bugsnag.leaveBreadcrumb('Attempting connection to Instagram (via backend)', { connectionData: connectionData });

    isConnecting = true;

    this.setState({
      showHome: false,
      fbStatus: 'connected',
      alertRestartError: false,
      successCode: null,
    });

    this.showConnectingProfile();

    let data;

    data = {
      'fb_user_id': connectionData.fbUserID,
      'primary_instagram_id': connectionData.primaryInstagramId,
      'primary_instagram_username': connectionData.primaryInstagramUsername,
      'access_token': connectionData.accessToken,
      'long_access_token': connectionData.longAccessToken,
      'provided_username': connectionData.providedUsername ?? connectionData.primaryInstagramUsername,
      'ms_session_token': connectionData.msSessionToken,
    };

    const brand = connectionData.brand ?? this.state.brand;

    let url = "/users";
    let payload = { user: data };

    if (brand) {
      data.brand = brand;
      payload = { ambassador: data, options: {} };

      if (this.state.sourceMsManage) {
        payload.options.managed = true;
      }

      url = '/ambassadors';
    }

    axios.defaults.headers.common['X-CSRF-Token'] = metaTag('csrf-token');

    axios({
      method: 'post',
      url,
      headers: { 'Accept': 'application/json' },
      data: payload
    })
      .then(res => {
        localStorage.setItem('mcSession', res.data.session_id);

        if (this.state.page === 'brand') {
          Bugsnag.leaveBreadcrumb('Connection complete (to brand)', { response: res.data }, 'request');

          this.setState({
            data: res.data,
            showDashboard: false,
            sessionId: res.data.session_id,
            successCode: res.data.success_code,
            username: res.data.profile?.instagram_username || this.state.username,
            sourceMsManageMessages: res.data.profile?.managed_messages,
          });

          this.finalizeAmbassador();
        } else {
          Bugsnag.leaveBreadcrumb('Connection completed (unaffiliated)', { response: res.data }, 'request');

          this.setState({
            data: res.data,
            showModalInvite: false,
            showDashboard: true,
            sessionId: res.data.session_id,
            successCode: res.data.success_code,
            username: res.data.profile?.instagram_username || this.state.username,
          });
        }
      })
      .catch(err => {
        Bugsnag.leaveBreadcrumb('Error connecting to Instagram (via backend)', { response: err.response }, 'request');

        const errorMsg = err.response.data.message ? err.response.data.message : 'If this error persists, email joe@mightycheck.com for support.'
        this.setState({
          alertRestartError: true,
          errorCode: err.response.data.error_msg_code,
          title: "There's an issue",
          description: errorMsg,
          heroImagePath: '/images/sample/red-ig.png',
          skipReload: true,
          successCode: null,
        });

        setTimeout(() => {
          this.handleFbLogout();
        }, 50);
      })
      .finally(() => {
        isConnecting = false;
      });
  }

  finalizeAmbassador() {
    const brandName = this.state.brandName;
    const successPhrase = this.state.sourceMsManage ? `Your profile can now be managed by ${brandName}.` : `You've successfully joined ${brandName}'s ambassador program.`;
    const extraPhrase = this.state.signupType === 'default' ? 'MightyCheck also created a profile that helps you better understand your engagement stats, audience, and media analytics. Feel free to check it out!' : '';

    this.setState({
      stage: 5,
      title: "You're in!",
      description: `${successPhrase} ${extraPhrase}`,
      heroImagePath: '/images/sample/success-heart.png'
    });

    window.location.hash = '';

    this.clearIgConnectData();
  }

  clearIgConnectData() {
    localStorage.removeItem('igConnect.providedUsername');
    localStorage.removeItem('igConnect.brand');
    localStorage.removeItem('igConnect.brandName');
    localStorage.removeItem('igConnect.brandImageUrl');
    localStorage.removeItem('igConnect.requestMessagePermissions');
    localStorage.removeItem('igConnect.sourceMsManage');
    localStorage.removeItem('igConnect.sourceMsManageMessages');
  }

  checkIfBusinessAccount(username) {
    const cleanedUsername = username.replace("@", "").replace("/", "").trim();
    return axios({
      method: 'get',
      url: `/connect/${cleanedUsername}/business_status`,
      headers: { 'Accept': 'application/json' }
    })
      .then(res => {
        return { status: res.status, statusText: res.statusText, is_business_account: res.data.is_business_account, profile: res.data.profile }
      });
  }

  showConnectingProfile(initialConstructorState = false) {
    Bugsnag.leaveBreadcrumb('Connecting state shown', {}, 'state');

    const newState = {
      stage: 2,
      title: "Connecting",
      description: "Please wait a moment while we build your profile.",
      heroImagePath: (!initialConstructorState ? this.state.userAvatarUrl : null),
      showModalInvite: true
    };

    if (initialConstructorState) {
      return newState;
    } else {
      this.setState(newState);
    }
  }

  handleUsernameInputChange = (e) => {
    let username = e.target.value.replace("@", "");
    this.setState({ usernameInput: username });
  }

  handleGetStarted = (e) => {
    if (this.state.useFbLoginForBusinessPage) {
      this.setState({
        showHome: false,
        showModalInvite: true,
        stage: 4,
        isBusinessAccount: true,
        businessAccountChecked: true,
      });

      this.showFacebookLogin(true, 0);

      e.preventDefault();
    } else {
      let username = this.state.usernameInput;

      this.setState(
        {
          username: username,
          showHome: false,
          showModalInvite: true,
          stage: 2,
          title: "Analyzing account",
          description: "",
        }
      );

      this.loginOrCreateUser(username);

      e.preventDefault();
    }
  }

  handleManagedMessagesChoice = (event, allowManageMessages) => {
    this.setState({
      sourceMsManageMessages: allowManageMessages,
      isPersistingPreferences: true,
    });

    const payload = {
      manage_messages: allowManageMessages
    };

    axios({
      method: 'post',
      url: `/session/update-preferences`,
      headers: {
        'Accept': 'application/json',
        'X-Session-ID': this.state.sessionId,
      },
      data: payload,
    })
      .then(res => {
        this.setState({
          sourceMsManageMessages: res.data.managed_messages,
          isPersistingPreferences: false,
        });
      })
      .catch(_err => {
        this.setState({
          isPersistingPreferences: false,
        });
      });

    event.preventDefault();
  }

  loginOrCreateUser(username) {
    this.checkIfBusinessAccount(username)
      .then(response => {
        let businessHash;

        if (response.status === 200 || response.status === 202) {
          const isBusinessAccount = response.is_business_account;

          if (isBusinessAccount) {
            businessHash = this.buildBusinessHash(response.profile.business_discovery);

            this.setState({ primaryInstagramId: response.profile.business_discovery.id });
          } else {
            businessHash = this.buildNotBusinessHash();

            this.handleFbLogout();
          }

          this.setState({
            isBusinessAccount: isBusinessAccount,
            businessAccountChecked: true,
            title: businessHash.resultTitle,
            heroImagePath: businessHash.heroImagePath,
            description: businessHash.description,
            userAvatarUrl: businessHash.userAvatarUrl,
            stage: 3
          });

          this.showFacebookLogin(isBusinessAccount);
        } else {
          //TODO: Show error message and let them try again
          console.log("didn't get response ok")
        }
      });
    // TODO: handle scenario where request fails
  }

  buildBusinessHash = (profile) => {
    return {
      userAvatarUrl: profile.profile_picture_url,
      resultTitle: "Everything looks great!",
      heroImagePath: "/images/sample/success-check.png",
      description: null
    };
  }

  buildNotBusinessHash = () => {
    return {
      resultTitle: "You need to create a professional account",
      heroImagePath: "/images/sample/red-ig.png",
      userAvatarUrl: "/images/sample/analyze.svg",
      description: null
    };
  }

  showFacebookLogin(isBusinessAccount, timeoutDuration = 1500) {
    let hash = extractHash();
    let resultTitle = null;
    let heroImagePath = null;
    let description = null;

    const { brand } = this.state;
    let phrase = brand ? this.state.brandName + " track your campaign & audience metrics" : " us track your audience & account metrics";

    if (hash.source == "ms" && !hash.ambassador || hash.ambassador == "false") {
      phrase = this.state.brandName + " track mentions & manage DMs";
    }

    const loginCallback = () => {
      if (isBusinessAccount) {
        heroImagePath = "/images/sample/success-check.png";
        description = "To help " + phrase + ", MightyCheck needs permission to:";
      } else {
        resultTitle = "Switch to a professional account";
        heroImagePath = "/images/sample/red-ig.png";
        description = "@" + this.state.username + " is not an Instagram professional account. You must set one up to continue.";
      }

      this.setState({
        stage: 4,
        title: resultTitle,
        heroImagePath: heroImagePath,
        description: description
      });
    };

    setTimeout(loginCallback, timeoutDuration);
  }

  handleAcceptInvite = (e) => {
    this.statusChangeCallback(this.state.fbResponse);
  }

  handleFbConnect = (e) => {
    if (this.state.useFbLoginForBusinessPage) {
      const loginScopes = this.buildLoginScopes();
      const loginUri = getFbLoginForBusinessPageUri(loginScopes);

      window.location.href = loginUri;
    } else {
      // TODO: confirm that this execution branch is never used
      this.loginWithFacebook();
    }

    e.preventDefault();
  }

  handleFbLogout = (event) => {
    if (event) {
      event.preventDefault();
    }

    if (this.state.useFbLoginForBusinessPage) {
      localStorage.removeItem('mcSession');

      this.clearIgConnectData();

      const redirectOrSetHash = () => {
        if (this.state.skipReload) {
          // window.location.hash = 'logged-out';
        } else {
          window.location.href = '/';
        }
      };

      axios({
        method: 'delete',
        url: '/session',
        headers: { 'Accept': 'application/json' },
      }).then(redirectOrSetHash).catch(redirectOrSetHash);
    } else if (window.fbLogout) {
      window.fbLogout();
    }
  }

  handleViewProfile = (e) => {
    e.preventDefault();

    this.setState({
      showModalInvite: false,
      showDashboard: true
    });

    if (window.history.pushState) {
      window.history.pushState({}, 'MightyCheck', '/');
    }
  }

  handleFbPageCheckboxCheck = () => {
    this.setState({
      fbPageCheckbox: !this.state.fbPageCheckbox
    });
  }

  handleFilterClick = (e) => {
    e.preventDefault();
    this.setState({ mediaFilter: e.target.text.toLowerCase() });
  }

  requestConversations = () => {
    axios.defaults.headers.common['X-CSRF-Token'] = metaTag('csrf-token');;

    this.setState({ isLoadingConversations: true });

    axios({
      method: 'get',
      url: '/conversations',
      headers: { 'Accept': 'application/json' },
    })
      .then(res => {
        const conversations = {
          ...this.state.conversations,
          index: res.data,
        };

        this.setState({
          conversations: conversations,
          isLoadingConversations: false,
          conversationsLoadCount: this.state.conversationsLoadCount + 1,
        });
      })
      .catch(err => {
        console.log(err.response);
      });
  }

  requestConversation = (conversationId) => {
    axios.defaults.headers.common['X-CSRF-Token'] = metaTag('csrf-token');;

    this.setState({ isLoadingConversation: true });

    axios({
      method: 'get',
      url: `/conversations/${conversationId}`,
      headers: { 'Accept': 'application/json' },
    })
      .then(res => {
        const conversations = {
          ...this.state.conversations,
        };

        conversations.data[conversationId] = res.data;

        const conversationLoadCount = {
          ...this.state.conversationLoadCount,
        }

        conversationLoadCount[conversationId] = this.state.conversationLoadCount[conversationId] + 1;

        this.setState({
          conversations: conversations,
          conversationLoadCount: conversationLoadCount,
        });
      })
      .catch(err => {
        console.log(err.response);
      })
      .finally(() => {
        this.setState({ isLoadingConversation: false });
      });
  }

  createConversationMessage = (conversationId, message) => {
    axios.defaults.headers.common['X-CSRF-Token'] = metaTag('csrf-token');;

    const payload = {
      message: message,
    };

    this.setState({
      isSendingMessage: true,
    });

    return axios({
      method: 'post',
      url: `/conversations/${conversationId}/message`,
      headers: { 'Accept': 'application/json' },
      data: payload,
    })
      .then(res => {
        const conversations = {
          ...this.state.conversations,
        };

        conversations.data[conversationId] = conversations.data[conversationId] || [];
        conversations.data[conversationId].push(res.data);

        this.setState({
          conversations: conversations,
          conversationMessageText: '',
        });
      })
      .catch(err => {
        console.log(err.response);
      })
      .finally(() => {
        this.setState({
          isSendingMessage: false,
        });
      });
  }

  handleConversationClick = (event) => {
    const conversationId = event.target.getAttribute("data-conversation-id");

    const conversations = {
      ...this.state.conversations,
    };

    conversations.data[conversationId] = conversations.data[conversationId] || [];

    const conversationLoadCount = {
      ...this.state.conversationLoadCount,
    }

    conversationLoadCount[conversationId] = this.state.conversationLoadCount[conversationId] || 0;

    this.setState({
      conversations: conversations,
      conversationId: conversationId,
      conversationMessageText: '',
      isSendingMessage: false,
      conversationLoadCount: conversationLoadCount,
      isPaneOpen: true,
    });

    this.requestConversation(conversationId);
    this.setRefreshConversationTimeout();

    event.preventDefault();
  }

  setRefreshConversationTimeout() {
    this.requestConversationTimeout = setTimeout(() => {
      if (typeof this.requestConversationTimeout === 'undefined') {
        return;
      }

      const conversationId = this.state.conversationId;

      if (!conversationId) {
        this.requestConversationTimeout = undefined;
        return;
      }

      this.requestConversation(conversationId);
      this.setRefreshConversationTimeout();
    }, 60000);
  }

  handleConversationMessageInput = (event) => {
    this.setState({
      conversationMessageText: (event.target.value || '').trim(),
    });
  }

  handleConversationMessageSend = (event) => {
    if (typeof event.key !== 'undefined' && event.key !== 'Enter') {
      return;
    }

    event.preventDefault();

    if (!this.state.isSendingMessage && this.state.conversationMessageText) {
      this.createConversationMessage(this.state.conversationId, this.state.conversationMessageText);
    }

    return false;
  }

  handleGrantMessagePermissionsClick() {
    if (typeof fbLogin === 'undefined') {
      return;
    }

    fbLogin();
  }

  componentDidUpdate(_prevProps, prevState) {
    const hasJustSentMessage = !this.state.isSendingMessage && prevState.isSendingMessage;
    const hasJustLoadedConversation = !this.state.isLoadingConversation && prevState.isLoadingConversation;
    const hasPaneJustOpened = this.state.isPaneOpen && !prevState.isPaneOpen;

    if (!hasJustSentMessage && !hasJustLoadedConversation && !hasPaneJustOpened) {
      return
    }

    const containerElement = this.state.conversationContainerRef.current?.parentElement;
    const messageElement = this.state.conversationMessageRef.current;

    const scrollContainerElement = () => {
      const currentContainerElement = this.state.conversationContainerRef.current?.parentElement;

      if (!currentContainerElement) {
        return;
      }

      currentContainerElement.scrollTo({
        top: currentContainerElement.scrollHeight,
        behavior: hasJustSentMessage ? 'smooth' : 'instant',
      });
    };

    if (containerElement) {
      scrollContainerElement();
    } else {
      setTimeout(scrollContainerElement, 50);
    }

    if (!hasJustLoadedConversation && messageElement) {
      messageElement.value = '';
      messageElement.focus();
    }
  }

  render() {
    let conversationMessagesHtml = null;
    let conversationPaneTitle = "Instagram Direct Message";

    if (this.state.conversationId) {
      const conversationSummary = this.state.conversations.index.find((conversation) => (conversation.id == this.state.conversationId));
      const conversationMessages = this.state.conversations.data[this.state.conversationId];

      if (conversationSummary) {
        conversationPaneTitle = `${conversationSummary.display_name || conversationSummary.username} - ${conversationPaneTitle}`;
      }

      if (this.state.isLoadingConversation && !this.state.conversationLoadCount[this.state.conversationId]) {
        conversationMessagesHtml = (
          <div className="conversation-loading-container">
            <p>Loading conversation...</p>
          </div>
        );
      } else if (!conversationMessages || !conversationMessages.length) {
        conversationMessagesHtml = (
          <div>No conversation is taking place here yet.</div>
        );
      } else {
        conversationMessagesHtml = conversationMessages.map((message) => {
          const displayName = conversationSummary.display_name || conversationSummary.username;

          return (
            <Message key={message.id} message={message} displayName={displayName} profilePictureURL={conversationSummary.profile_picture_url} />
          );
        });
      }
    }

    return (
      <div>
        {false && (this.state.igConnectAction || this.state.stage != 2) && (
          <WarningBanner>
            <strong>Warning:</strong>
            &nbsp;
            <span>Facebook is currently experiencing technical difficulties you may run into issues with connecting your account.</span>
          </WarningBanner>
        )}
        <SlidingPane
          overlayClassName="panel"
          isOpen={this.state.isPaneOpen}
          title={conversationPaneTitle}
          onRequestClose={() => {
            clearTimeout(this.requestConversationTimeout);
            this.requestConversationTimeout = undefined;
            this.setState({ isPaneOpen: false });
          }}
        >
          <div ref={this.state.conversationContainerRef}>
            {conversationMessagesHtml}
          </div>
          <div className="panel-action-bar-bottom">
            <input ref={this.state.conversationMessageRef} required type="text" placeholder="Write your message" className="dm-send-input" onInput={this.handleConversationMessageInput} onKeyDown={this.handleConversationMessageSend} disabled={this.state.isSendingMessage} />
            <input type="submit" value={this.state.isSendingMessage ? 'Sending...' : 'Send'} className="btn btn-primary dm-send-submit" onClick={this.handleConversationMessageSend} disabled={this.state.isSendingMessage} />
          </div>
        </SlidingPane>
        <div className="container tight-container js-main">
          <LoggedOutHome
            handleGetStarted={this.handleGetStarted}
            handleUsernameInputChange={this.handleUsernameInputChange}
            showHome={this.state.showHome}
            usernameInput={this.state.usernameInput}
            useFbLoginForBusinessPage={this.state.useFbLoginForBusinessPage}
            buildLoginScopes={this.buildLoginScopes.bind(this)}
          />
          {this.state.showDashboard &&
            <Dashboard data={this.state.data}
              mediaFilter={this.state.mediaFilter}
              handleFilterClick={this.handleFilterClick}
              handleFbLogout={this.handleFbLogout}
              requestConversations={this.requestConversations}
              isLoadingConversations={this.state.isLoadingConversations}
              conversationsLoadCount={this.state.conversationsLoadCount}
              conversations={this.state.conversations}
              handleConversationClick={this.handleConversationClick}
              handleGrantMessagePermissionsClick={this.handleGrantMessagePermissionsClick} />}
        </div>
        <ConnectModal
          page={this.state.page}
          showModalInvite={this.state.showModalInvite}
          handleAcceptInvite={this.handleAcceptInvite}
          handleGetStarted={this.handleGetStarted}
          handleFbConnect={this.handleFbConnect}
          handleManagedMessagesChoice={this.handleManagedMessagesChoice}
          handleViewProfile={this.handleViewProfile}
          handleUsernameInputChange={this.handleUsernameInputChange}
          handleFbLogout={this.handleFbLogout}
          handleFbPageCheckboxCheck={this.handleFbPageCheckboxCheck}
          stage={this.state.stage}
          fbStatus={this.state.fbStatus}
          title={this.state.title}
          usernameInput={this.state.usernameInput}
          username={this.state.username}
          description={this.state.description}
          heroImagePath={this.state.heroImagePath}
          userAvatarUrl={this.state.userAvatarUrl}
          isBusinessAccount={this.state.isBusinessAccount}
          fbPageCheckbox={this.state.fbPageCheckbox}
          alertRestartError={this.state.alertRestartError}
          errorCode={this.state.errorCode}
          successCode={this.state.successCode}
          brand={this.state.brand}
          brandName={this.state.brandName}
          sourceMsManage={this.state.sourceMsManage}
          sourceMsManageMessages={this.state.sourceMsManageMessages}
          requestMessagePermissions={this.state.requestMessagePermissions}
          useFbLoginForBusinessPage={this.state.useFbLoginForBusinessPage}
          buildLoginScopes={this.buildLoginScopes.bind(this)}
          isPersistingPreferences={this.state.isPersistingPreferences}
        />
      </div>
    );
  }
}
